<template>
    <div class="card">
        <h5>{{ $t('menu.settleBets') }}</h5>
        <BetsOperations :operationType="'settle'"></BetsOperations>
    </div>
</template>
<script>
import BetsOperations from './Components/BetsOperations.vue';
export default {
    name: 'settleBets',
    components: {
        BetsOperations,
    },
    data() {
        return {};
    },
};
</script>